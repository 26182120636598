@font-face {
  font-family: 'Finmo-font Regular';
  src: url('./assests/fonts/1a930247.woff2');
}
/* @font-face {
  font-family: 'Finmo-font Demi';
  src: url('./assests/fonts/AvenirNextLTPro-Demi.otf');
}
@font-face {
  font-family: 'Finmo-font Medium';
  src: url('./assests/fonts/AvenirNextLTPro-Medium.otf');
}
@font-face {
  font-family: 'Finmo-font Bold';
  src: url('./assests/fonts/AvenirNextLTPro-Bold.otf');
} */


.MuiMonthPicker-root{
  background: none;
  width: 310px;
  margin: 0 4px;
}

.MuiPickersMonth-root{
  margin: 8px 0px !important;
  border-radius: 16px;
  height: 36px;
  background-color: transparent;
  border: none;
}

.MuiPickersMonth-root:hover{
  background-color: rgba(34, 51, 84, 0.1);
}


.MuiPickersMonth-root.Mui-selected{
  color: white;
  background-color: #242E6F;
}